<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <NewUpCaveat :refreshList="refetchData"></NewUpCaveat>
          <!-- <b-button
            v-if="data !== null"
            variant="primary"
            @click="
              $router.push({
                name: 'invoice-add',
                params: { matter: data }
              })
            "
          >
            Add Invoice
          </b-button> -->
          <!-- <router-link
            v-if="data !== null"
            :to="{
              name: 'invoice-add',
              params: { matter: data }
            }"
            target="_blank"
            ><b-button class="ml-1" variant="primary"> Add Invoice </b-button>
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'invoice-matter'
            }"
            target="_blank"
            ><b-button class="ml-1" variant="primary"> Select Matter </b-button>
          </router-link> -->
          <!-- <b-button v-else variant="primary" :to="{ name: 'invoice-matter' }">
            Select Matter
          </b-button> -->
          <!-- <router-link
            :to="{
              name: 'invoice-add-blank'
            }"
            target="_blank"
            ><b-button class="ml-1" variant="success">
              Add Without Matter
            </b-button>
          </router-link> -->
        </b-col>
        <!-- Search -->
        <b-col cols="6" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                debounce="500"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refListTable"
      :items="fetchData"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <template #cell(created_at)="data">
        <p class="font-weight-bold text-nowrap">
          {{ getDate(data.value) }}
        </p>
      </template>

      <template #cell(crime_number)="data">
        <p class="font-weight-bold text-nowrap mb-50">
          {{ data.item.crime_number }} / {{ data.item.crime_year }}
        </p>
        <small class="font-weight-bold text-nowrap">
          {{ data.item.court_name }}
        </small>
      </template>

      <template #cell(crime_type)="data">
        <p class="font-weight-bold text-nowrap">
          {{
            data.item.crime_type == 'st_number' ? 'ST Number' : 'Crime Number'
          }}
        </p>
      </template>

      <template #cell(district)="data">
        <p class="font-weight-bold text-nowrap mb-50">
          District: {{ data.item.dist_name }}
        </p>
        <div v-if="Object.keys(data.item).includes('police_st_name')">
          <small
            class="font-weight-bold text-nowrap"
            v-if="data.item.police_st_name !== null"
          >
            Police Station: {{ data.item.dist_name }}
          </small>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="">
          <b-badge
            v-if="
              Object.keys(data.item).includes('for_date') &&
              !isDateLessThanToday(data.item.for_date)
            "
            variant="primary"
            pill
            v-b-tooltip.hover
            title="Cases"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            @click="openCasesModel(`modal-fir-cases-${data.item._id}`)"
            class="badge-round cursor-pointer mb-50 mr-50"
          >
            <feather-icon
              :id="`fir-${data.item._id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-25"
            />
            <span>{{ data.item.data.length }} Cases</span>
          </b-badge>
          <b-badge
            v-else
            variant="danger"
            pill
            v-b-tooltip.hover
            title="Expired"
            class="badge-round mr-1"
          >
            <feather-icon
              :id="`matter-${data.item._id}-expired-icon`"
              size="16"
              class="mr-25"
            />
            <span>Expired</span>
          </b-badge>

          <!-- modal Scrolling Content inside Modal-->
          <b-modal
            :id="`modal-fir-cases-${data.item._id}`"
            scrollable
            title="Cases List"
            cancel-title="Close"
            cancel-variant="outline-secondary"
          >
            <b-card-text
              v-for="(content, index) in data.item.data"
              :key="index"
            >
              <b-list-group-item>
                <b-row>
                  <b-col class="col-12">
                    <b-row class="justify-content-between">
                      <b-col class="mr-auto cursor-pointer">
                        <h5 class="font-weight-bold">
                          {{ getUniqueId(content) }}
                        </h5>
                      </b-col>
                      <b-col class="text-end">
                        <b-row
                          v-if="Object.keys(content).includes('status')"
                          class="justify-content-end"
                        >
                          <b-badge
                            variant="light"
                            pill
                            class="badge-round cursor-pointer"
                          >
                            <span
                              :class="
                                content.status === `DISPOSED`
                                  ? `text-danger`
                                  : `text-primary`
                              "
                              >{{ content.status }}</span
                            >
                          </b-badge>
                        </b-row>
                      </b-col>
                    </b-row>
                    <div v-if="Object.keys(data.item).includes('court_name')">
                      <small class="text-secondary font-italic">{{
                        data.item.court_name
                      }}</small>
                    </div>
                    <router-link
                      class="text-primary"
                      :to="{
                        name: 'up-caveat-preview',
                        params: {
                          id: data.item._id,
                          unique_id: content.unique_id
                        }
                      }"
                      target="_blank"
                    >
                      <h6 class="text-primary">
                        {{ content.title }}
                      </h6>
                    </router-link>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-card-text>
          </b-modal>

          <b-badge
            variant="danger"
            pill
            v-b-tooltip.hover
            title="Delete"
            class="badge-round cursor-pointer mr-1"
            @click="deleteAlert(data.item._id)"
          >
            <feather-icon
              :id="`matter-${data.item._id}-delete-icon`"
              icon="DeleteIcon"
              size="16"
              class="mr-25"
            />
            <span>Delete</span>
          </b-badge>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BModal,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  VBTooltip,
  VBToggle
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useTrackerList from './useTrackerList'
import moment from 'moment'
import axios from 'axios'
import NewUpCaveat from '../NewUpCaveat.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BInputGroup,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    NewUpCaveat,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    matter: {
      default: null
    }
  },
  methods: {
    openCasesModel(id) {
      this.$bvModal.show(id)
    },
    getDate(date) {
      return moment(date).format('Do, MMMM YYYY')
    },
    isDateLessThanToday(date) {
      const currentDate = new Date()
      const givenDate = new Date(date)

      return givenDate < currentDate
    },
    deleteAlert(_id) {
      console.log('ID' + _id)
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this Tracker.',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('upCaveatStore/deleteTracker', {
              ctx: this,
              _id: _id
            })
            .then((result) => {
              this.refetchData()
            })
        }
      })
    }
  },
  setup(props) {
    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      refListTable,
      refetchData
    } = useTrackerList()

    return {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      refListTable,
      refetchData
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
